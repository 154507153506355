@import "../../styles/mixins";

.stepContainer {
  height: 100px;
  width: 100%;
  background-color: #217ab8;
  color: #fff;
  padding-top: 1rem;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .stepDot {
      width: 25px;
      height: 25px;
      border-radius: 20px;
      background-color: transparent;
      color: #fff;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }

  .activeStep {
    .stepDot {
      background-color: #fff;
      color: #217ab8;
    }
  }
}

