.viewIncidentWrapper {
  min-height: 400px;
  h4,
  div.MuiTypography-h4 {
    font-size: 1.2rem !important;
  }
  .card {
    border-radius: 1rem;
    padding: 8px;
    width: auto;
    position: relative;
    box-shadow: none;
    .incidentName {
      font-size: 1.2rem;
      font-weight: 600;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
    }
    .incidentStatus {
      font-size: 0.8rem;
      font-weight: 500;
    }
    .closeHeader {
      padding: 0;
      span {
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
  }
}
