@import "../../styles/mixins";

.navItems {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  gap: 24px;

  &__item {
    color: #fff;
    display: flex;
    align-items: center;
    user-select: none;
    font-size: 14px;
    gap: 0.25rem;
    flex-direction: column;
    color: black;
    width: 100%;

    .desktopIcon {
      display: none;
      @include tablet {
        display: block;
      }
    }

    @include tablet {
      color: white;
    }

    &_selected {
      color: #2271ba;

      @include tablet {
        color: white;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navItemIndicator, .navItemSelectedIndicator {
    display: none;
  }

  .navItemContainer {
    width: 33%;
  }

  @include tablet {

    .navItemContainer {
      width: auto;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      height: 100%;
      margin: 0 1rem;

      .navItemIndicator {
        border-bottom: 0.5rem solid transparent;
        margin-top: -0.5rem;
        display: block;
      }
    
      .navItemSelectedIndicator {
        border-bottom: 0.5rem solid white;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-top: -0.5rem;
        display: block;
      }

      .mobileIcons {
        display: none;
      }

    }
  }
  
}

// @media (min-width: 576px) {
//   .nav-items {
//     display: flex;
//     flex-direction: column;
//   }
// }
