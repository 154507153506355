@import "../../styles/mixins";
@import "../../styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 50px;
  z-index: 1001;
  max-width: 100%;
  width: 100%;
  background-color: transparent;

  @include tablet {
    left: 0;
    bottom: 0;
    width: fit-content;
  }

  .listType {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: none;
    margin: 0.75rem 1rem;
    width: fit-content;
    @include desktop {
      justify-content: start;
    }
    .toggleView {
      background-color: $white;
      border-radius: 0.75rem;
      padding: 0.5rem 0.75rem;
    }
  }

  .cardViewWrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 250px;
    margin: 0 0 1rem;
    .cardContainer {
      display: inline-block;
      min-width: 300px;

      .card {
        padding: 0.5rem;
        margin-left: 0.75rem;
        border-radius: 1rem;
        cursor: pointer;
      }
    }

    @include tablet {
      max-width: 100%;
      min-width: 300px;
    }
  }
  .listViewWrapper {
    .viewContent {
      background-color: $white;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      white-space: nowrap;
      height: 400px;
      margin: 1rem;
      border-radius: 1rem;
      .listContainer {
        margin: 0;
        border-bottom: 1px solid #00000029;
        .listCard {
          display: flex;
          // margin-bottom: 12px;
          padding: 8px;
          align-items: center;
          justify-content: center;
          border: none;
          box-shadow: none;
          border-radius: 0;
          cursor: pointer;
        }
      }
      .listContainer:last-child {
        border-bottom: none;
      }

      @include tablet {
        width: 600px;
        height: 600px;
      }
    }

    @include tablet {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.listViewWidth {
  width: auto;
  left: 0;

  @include mobile {
    right: 0;
  }
}

.viewContainer {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  margin: 1rem;
  background-color: transparent;

  @include tablet {
    width: 600px;
    height: 600px;
    top: unset;
  }
}
