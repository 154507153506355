// @import "./custom.scss";
@import "./mixins";
@import "./xtra";
@import "./variables";
// @import "./fonts";

html,
body {
  font-family: "Roboto" !important;
  scroll-behavior: smooth;
}

// .slider-home {
//   .slick-list {
//     .slick-track {
//       .slick-slide {
//         &:nth-child(1) {
//           position: relative;

//           &::before {
//             content: "";
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 100%;
//             height: 100%;
//             background-image: linear-gradient(
//               to right,
//               rgba($white, 0.75) 25%,
//               rgba($white, 0.25) 50%,
//               transparent 100%
//             );
//           }
//         }
//       }
//     }
//   }
// }
//HEADING
h1 {
  font-size: 36px;
  line-height: 44px;
  @include mobile {
    font-size: 24px;
    line-height: 30px;
  }
}
.h-2 {
  font-size: 30px;
  line-height: 36px;
  @include mobile {
    font-size: 20px;
    line-height: 34px;
  }
}
.h-3 {
  font-size: 24px;
  line-height: 30px;
  @include mobile {
    font-size: 16px;
    line-height: 20px;
  }
}

.yellow-life-title {
  font-size: 20px;
  line-height: 24px;
  @include mobile {
    font-size: 16px;
  }
}

.para-1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

// FONT SIZE
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-24 {
  font-size: 24px;
}
.text-28 {
  font-size: 28px;
}
.text-64 {
  font-size: 64px;
}

//LINE HEIGHT
.l-20 {
  line-height: 20px;
}
.l-24 {
  line-height: 24px;
}
.l-28 {
  line-height: 28px;
}
.l-32 {
  line-height: 32px;
}
.l-36 {
  line-height: 36px;
}
//WIDTH
.w-112px {
  width: 112px;
}

//OPACITY
.op-1 {
  opacity: 0.1;
}
.op-25 {
  opacity: 0.25;
}
.op-50 {
  opacity: 0.5;
}
.op-75 {
  opacity: 0.75;
}
.op-100 {
  opacity: 1;
}

//BORDER

.borderBlack {
  border: 1px solid $black;
}
.borderWhite {
  border: 1px solid $white;
}
.borderYellow {
  border-color: $theme-custom-yellow !important;
}
.border-1 {
  border: 1px solid;
}
.border-2 {
  border: 2px solid;
}

.arrowSliderNone {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
.aboutSlider {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-slide > div {
    padding: 0 48px;

    @include tablet {
      padding: 0 24px;
    }
    @include mobile {
      padding: 0;
    }
  }
  .slick-list {
    margin: 0 -48px;
    @include tablet {
      margin: 0 -24px;
    }
    @include mobile {
      margin: 0;
    }
  }

  .slick-dots {
    // margin-top: 32px;
    height: 56px;
    bottom: -106px;
    color: $theme-custom-pink;
    background-color: $white;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    li.slick-active button:before {
      font-size: 12px;
      color: $theme-custom-purple;
      height: 24px;
      width: 24px;
    }
    li button:before {
      font-size: 12px;
      color: $theme-custom-pink;
      height: 24px;
      width: 24px;
    }
  }
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: $theme-custom-purple;
  font-weight: bold;
}
.bannerCaraousel {
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .slick-dots {
    // margin-top: 32px;
    height: 48px;
    bottom: -48px;
    color: $theme-custom-yellow;
    background-color: $white;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    li.slick-active button:before {
      font-size: 12px;
      color: $theme-custom-purple;
      height: 24px;
      opacity: 1;
      width: 24px;
    }
    li button:before {
      font-size: 12px;
      color: $theme-custom-yellow;
      height: 24px;
      opacity: 1;
      width: 24px;
    }
  }
}
.videoSlider {
  .slick-dots {
    left: 0;
    color: $theme-custom-pink;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    li.slick-active button:before {
      font-size: 12px;
      color: $theme-custom-yellow;
      height: 24px;
      width: 24px;
    }
    li button:before {
      font-size: 12px;
      color: $theme-custom-orange;
      height: 24px;
      width: 24px;
    }
  }
}

//HEADER TOGGLE BUTTON

.hamburger {
  &.is-active {
    .line {
      background-color: $white;
    }
  }
}
.hamburger .line {
  width: 30px;
  height: 5px;
  background-color: $theme-custom-purple;
  display: block;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

/* ONE */
#hamburger-1.is-active .line {
  width: 36px;
  height: 5px;
}
#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}
