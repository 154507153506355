@import "../../styles/mixins";

.formContainer {
  position: absolute;
  //   display: flex;
  //   flex-direction: column;
  z-index: 1002;
  bottom: 50px;
  background-color: #fff;
  width: 100%;

  .photoUploadBtn {
    cursor: pointer;
    padding: 0.5rem;
    background-color: #217ab8;
    color: white;
    font-weight: 500;
  }

  @include tablet {
    bottom: 0;
    width: 450px;
    right: 20px;
  }

  .progressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .buttonContainer {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    border-top: 1px solid #e6e6e6;
    .button {
      flex-grow: 1;
      border-radius: 10px;
    }
  }

  .formStepContainer {
    display: none;
    @include tablet {
      display: block;
    }
  }

  .formWrapper {
    @include tablet {
      padding: 24px 16px;
      min-height: 350px;
      max-height: calc(100vh - 20em);
      overflow-y: scroll;
    }

    .searchWrapper {
      display: none;
      @include tablet {
        display: block;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      @include mobile {
        padding: 24px 16px;
        height: calc(100vh - 17em);
        overflow: scroll;
      }
    }
  }
}
.snackbar {
  @include tablet {
    right: 120px !important;
    left: auto !important;
  }
}

.smallSelectDropdown {
  min-width: 75vw !important;
  left: 1rem !important;
  ul {
    li {
      min-height: 35px;
    }
  }
}
