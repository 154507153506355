@import "../../styles/mixins";
.infoDialogContent {
  overflow: hidden;
  .infoList {
    border: unset;
    .infoListItem {
      padding: unset;
      > div {
        width: 20px;
      }
      cursor: default;
      &:hover {
        background-color: initial;
      }
    }
  }
  .infoMessage {
    padding-bottom: 8px;
  }
}

#customized-dialog-title {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 0.5rem;
  }
}
