@import "../../styles/mixins";

.headerContainer {
  background-color: #217ab8;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;

  .appLogo {
    width: 2.5rem;
  }

  .searchContainer {
    width: 100%;

    @include tablet {
      width: auto;
      margin-left: auto;
    }
  }

  .navContainer {
    height: 100%;
    display: block;
    margin-left: 3rem;
    @include mobile {
      display: none;
    }
  }

  .stepContainer {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #217ab8;
    box-shadow: 0px 8px 4px #b8b8b8;
    color: #fff;
    gap: 64px;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .stepDot {
        width: 25px;
        height: 25px;
        border-radius: 20px;
        background-color: transparent;
        color: #fff;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }
    }

    .activeStep {
      .stepDot {
        background-color: #fff;
        color: #217ab8;
      }
    }
  }
}

.newStepHeader {
  background-color: white;

  @include tablet {
    background-color: #217ab8;
  }

  .appLogo {
    display: none;

    @include tablet {
      display: block;
    }
  }
}
