// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$gray-f9: #f9f9f9;
$gray-f10: #e8e2e4;
$white: #ffffff;
$gray-dark: #c9cdd8;
$gray-light: #efefef;
$label-text: #6f7588;
$border-color: #ad9e9e;
$border-color-light: #ebeaed;
$arrow-color: #a8b1ce;
$textColor: #21243c;
$bodyColor: #faf8ff;

// Color Shades (Theme Based - Xclusive)
$theme-primary: #532eb3;
$theme-primary-dark: #2e0e81;
$theme-purple-dark: #452a7f;
$theme-secondary: #62ffb1;
$theme-dark: #25282b;
$theme-accent: #ff8000;
$theme-success: #037e56;
$theme-info: #9c6dff;
$theme-warning: #ff8238;
$theme-green: #0a7276;
$theme-purple: #592b88;
$theme-warning-light: #fef6e0;
$theme-danger: #e24c4b;
$theme-yellow: #ea9d04;
$theme-cyan: #35d2cc;
$theme-pink: #f24291;
$theme-pink-2: #ec6a5a;
$theme-pink-3: #e76557;
$theme-link: #004b8f;
$theme-background: #f4efff;
$theme-custom-purple: #4b2a92;
$theme-custom-yellow: #ffd83b;
$theme-custom-pink: #f7aaac;
$theme-custom-light-blue: #f7f7f8;
$theme-gray-5: #4d4d4d;
$theme-peach-1: #fbdfc7;
$theme-custom-orange: #ea6252;
