@import "../../styles/mixins";

.autoCompleteContainer {
  position: relative;

  .autoCompleteTextField {
    width: 100%;
    border-radius: 4px;

    @include tablet {
      width: 415px;
    }
  }
}

input {
  padding-right: 42px;
}

.searchIcon {
  position: absolute;
  right: 10px;
  display: flex;
  height: 100%;
  align-items: center;
}

ul {
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 999;
  border: 1px solid #ccc;
  margin-top: 8px;
  border-radius: 8px;
  padding-left: 0;
}

li {
  list-style: none;
  padding: 12px;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
}
