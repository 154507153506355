@import "./styles/mixins";

.main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include mobile {
    height: calc(100vh - 3.5rem);
    padding-bottom: 3.5rem;
  }

  .form-step-container {
    display: block;
    width: 100%;

    @include tablet {
      display: none;
    }
  }
}

.sidepane-container {
  width: 100%;
  background: #fff;
  height: 10%;
}

.nav-container {
  width: 100%;
  box-shadow: 0 -2px 5px #d4d4d4;
  z-index: 999999;
  position: fixed;
  height: 3.5rem;
  background: #ffffff;
  bottom: 0;
  width: 100%;
  z-index: 1000;

  @include tablet {
    display: none;
  }
}

.map-viewer-container {
  width: 100%;
  flex: 1 1 auto;
  position: relative;
}

.body-container {
  position: absolute;
  z-index: 999;
}

.view-incident-popup-container
  .leaflet-popup-content-wrapper
  .leaflet-popup-content {
  max-height: 25rem;
  overflow: scroll;
}

.location-alert-message {
  font-size: 12pt;
  font-weight: 600;
}

.addressPinButton {
  display: flex !important;
  font-style: italic;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 9pt;
  color: #002641;
  font-weight: 500;
}

@media (min-width: 576px) {
  // .main-container {
  //   flex-direction: row;
  // }

  .sidepane-container {
    height: 100%;
    width: 30%;
    min-width: 350px;
  }
}

.MuiSnackbar-root {
  @include mobile {
    bottom: 8rem !important;
  }
  @include tablet {
    bottom: 4rem !important;
  }
}
