@import "../../styles/mixins";

.layout-container {
  height: 100%;
  position: relative;
}

.map-container {
  height: 100%;
}

.autocomplete-container {
  position: absolute;
  z-index: 999;
  left: 1rem;
  top: 1rem;
  background-color: white;
}

.drop-pin-container {
  position: absolute;
  bottom: 1.8rem;
  right: 3.5rem;
  z-index: 999;
  background-color: #fff;
  padding: 6px;
}

.drop-pin-container:hover {
  cursor: pointer;
  background-color: #ececec;
}

.modal-overlay-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  background-color: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  padding: 1rem;
}

.form-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @include tablet {
    min-width: 500px;
    min-height: 500px;
    left: unset;
    top: unset;
    bottom: 4rem;
    margin: 3rem;
  }
}

.view-incident-popup-container {
  .leaflet-popup-content-wrapper {
    padding: 0;
    .leaflet-popup-content {
      margin: 0;
      width: 350px !important;
      padding: 10px;
      @include mobile {
        max-height: 50vh;
        max-width: 70vw;
        img {
          object-fit: contain;
          width: 100%;
          height: 100px;
        }
      }
    }
  }
  .leaflet-popup-close-button {
    top: 12px !important;
    right: 12px !important;
    font-size: 20px !important;
  }
}
